import { TFunction } from 'i18next'
import { getReadableMonthName, timeZones } from './constants'

export const isDatesEqual = (x: Date | string, y: Date | string) => {
  return new Date(x).toLocaleDateString() === new Date(y).toLocaleDateString()
}

export const secondsToHours = (seconds: number) => {
  const hours = Math.floor(seconds / 3600)
    .toString()
    .padStart(2, '0')
  const minutes = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, '0')
  return `${hours}:${minutes}`
}

export const getAllTimeZones = () => {
  const list: { label: string; value: string }[] = []

  timeZones.forEach(tz => {
    const time = new Intl.DateTimeFormat('en-US', {
      timeZone: tz,
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }).format(new Date())

    list.push({
      label: `${tz} ${time}`,
      value: tz,
    })
  })

  return list
}

export const joinDateWithTimeSlot = (date?: Date, timeSlot?: number) => {
  if (!date || typeof timeSlot !== 'number') return ''
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')

  const hours = String(Math.floor(timeSlot / 3600)).padStart(2, '0')
  const minutes = String(Math.floor((timeSlot % 3600) / 60)).padStart(2, '0')
  const seconds = String(timeSlot % 60).padStart(2, '0')

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`
}

export const getStartEndDates = (month: Date) => {
  const startDate = new Date(month.getFullYear(), month.getMonth(), 1)
    .toISOString()
    .slice(0, 10)
  const endDate = new Date(month.getFullYear(), month.getMonth() + 1, 1)
    .toISOString()
    .slice(0, 10)

  return { startDate, endDate }
}

export const formatDate = (date: Date, t: TFunction) => {
  const dayShort = date.toLocaleDateString('en-US', { weekday: 'short' })

  return `${dayShort}, ${t(getReadableMonthName(date))} ${date.getDate()}`
}
